
// @ts-nocheck
import {Component, Watch} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import UserEditor from "@/components/user/UserEditor.vue";
import User from "@/assets/Entities/User";
import GenericListComponent, {Filter} from "@/components/generic/GenericListComponent.vue";
import {RequestType, TargetType, UserType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";
import Webservice from "@/assets/service/Webservice";

@Component({
  components: {UserEditor}
})
export default class UserList extends GenericListComponent<User> {
  created() {
    this.loadItems();
    if (this.$store.getters.currentUser.userRole === UserType.Admin) {
      Webservice.performRequest(RequestType.GET,
          {type: TargetType.SCHOOL},
          {},
          val => {
            this.$store.commit('SET_AVAILABLE_SCHOOLS', val);
          })
    }
  }

  headers: DataTableHeader[] = [
    {
      text: this.$t('User.List.Table.Header1').toString(),
      value: 'login',
      width: '60%'
    },
    {
      text: this.$t('User.List.Table.Header2').toString(),
      value: 'immunLocalized',
      width: '30%'
    },
    {
      text: this.$t('User.List.Table.Header3').toString(),
      value: 'action',
      sortable: false,
      width: '10%'
    }];

  // @ts-ignore
  get filters(): Filter[] {
    let filters: Filter[] = [];
    if (this.$store.getters.currentUser.role === UserType.Admin) {
      filters.push({
        title: 'school',
        items: this.$store.getters.availableSchools,
        text: 'name',
        value: '_id'
      });
    }
    return filters;
  }

  get selectedSchoolId() {
    return this.$store.getters.currentUser.role === UserType.Admin ? (this.filterValues['school'] || -1)
        : (this.$store.getters.currentUser.schoolId || this.$store.getters.currentUser.school._id);
  }

  @Watch('items')
  watchItems() {
    for (let i = 0; i < this.items.length; i++) {
      // get in User won't work...
      if (this.items[i].last_questions) {
        this.items[i].lastQuestionsLocalized = this.$d(new Date(this.items[i].last_questions)).toString()
      }
      if (this.items[i].immun) {
        this.items[i].immunLocalized = this.$d(new Date(this.items[i].immun)).toString()
      }
      this.items[i].schoolId = this.selectedSchoolId
    }
  }

  get params(): SchoolURLParams {
    return {
      type: TargetType.USER,
      schoolId: this.selectedSchoolId
    }
  };

  showInactive: boolean = false;

  checkBoxLabel: string = 'User.List.InactiveUsers';

  title = "User.List.Title";
  addButtonTitle = "User.List.AddUser";
  addButtonIcon = "mdi-account-plus";
  elementActionIcon = "mdi-account-edit";
  dialog = "user-editor";
  rowsPerPageText: string = 'User.RowsPerPage';
}
